@import "_variables";
#pageContainer {
    padding-top: 20vh;
    padding-bottom: 10vh;
    p {
        font-size: 18px;
        margin: auto;
        width: 80%;
        line-height: 5vh;
        font-weight: bold;
        padding: 2vh;
        background-color: $white;
    }
    a {
        text-decoration: none;
        color: $blue;
        i {
            margin-right: 5px;
        }
        &:hover {
            color: $red;
        }
    }
}

#emailAdress {
    margin-left: 5px;
}

#enrollForm {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.sectionHeader3 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 3vw;
    font-weight: 900;
    color: $blue;
    margin-bottom: 5vh;
}

.card {
    width: 80%;
    text-align: center;
    margin: 5vh 5vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: $white;
    i {
        margin-top: 2vh;
    }
}
.fa-star, .fa-clock-o{
    margin-right: 5px;
}

#cardContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-evenly;
}

#parHeader {
    font-weight: bold;
    font-size: larger;
    color: $red;
}

#parHeader2 {
    font-weight: bold;
    color: $red;
}

#centerClosureList {
    line-height: 3;
    font-size: 20px;
    font-weight: bold;
}

.icon {
    height: 25px;
    width: 25px;
    margin-right: 10px;
}

table {
    margin-top: 2vw;
    margin-left: auto;
    margin-right: auto;
    font-size: larger;
    background-color: $white;
}

table,
th,
td {
    border: 2px solid $blue;
    border-collapse: collapse;
}

th,
td {
    padding: 15px;
}

td:first-child {
    font-weight: bold;
}

#rateExplanation {
    text-align: left;
    line-height: 4;
}

#pageTable {
    margin-bottom: 2vw;
th,td {
background-color: $white;
padding: 10px 5px;
th{
    text-align: left;

}}
}
#galleryContainer>div:nth-child(7),
#pageContainer>div {
    margin-top: 5vw;
}

.schoolAgeHeader {
    color: red;
    text-transform: uppercase;
    margin-top:15px;
}

.equal{
    height: 25vh;
    background-color: $white;
}
#equalImage{
    width:150px;
    height:60px;
}
@media screen and (max-width: 768px) {
    #enrollForm {
        grid-template-columns: 1fr 1fr;
    }
    #cardContainer {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 541px) {
    #cardContainer {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 411px) {
    #enrollForm {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 280px) {
    #galleryContainer h2 {
        font-size: 22px;
    }
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
    /* Force table to not be like tables anymore */
    table {
        width: 90%;
    }
  
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    tr {
        margin: 0 0 1rem 0;
    }
    tr:nth-child(odd) {
        background: #ccc;
    }
    td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid rgb(204, 194, 194);
        position: relative;
        padding-left: 50%;
    }
    td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 0;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }
    #programsPagePhoto1{
        display: none;
       }
    #pageTable{
        text-align: center;
        tr{
            margin: 0;
        }
        td{
            padding-left: 0;
            border: none;
        }
        th{
            background-color: $yellow;
            border: none;
        }
    }
    /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
    td:nth-of-type(1):before {
        content: "";
    }
    td:nth-of-type(2):before {
        content: "1st Child";
    }
    td:nth-of-type(3):before {
        content: "2nd Child";
    }
    td:nth-of-type(4):before {
        content: "Part Time";
    }
    td:nth-of-type(5):before {
        content: "12.5hr a week";
    }
}