$blue: #0779E4;
$yellow: #ffE75E;
$lightyellow: #d6c97c;
$green: #52de97;
$red: #F12D2D;
$turqoise: #00ead350;
$darkgrey:rgb(74, 65, 65);
$pink: pink;
$darkred: rgb(115, 7, 7);
$darkturqoise: rgb(0, 242, 255);
$black:black;
$white:white;
$lightgrey: rgba(186, 180, 180, 0.476);
$eventgrey:rgba(186, 180, 180, 0.898);
$grey: rgb(46, 41, 41);
$orange: orange;