@import "_variables";
#careerForm {
    border: 6px solid $blue;
    margin: 5vh 0;
    border-radius: 5%;
    display: inline-block;
    text-align: center;
    align-items: center;
    line-height: 2;
    padding: 5vh;
    font-weight: bold;
    background-color: $white;
    textarea {
        font-family: 'Quicksand', sans-serif;
    }
}

label {
    margin-right: 8px;
}

input {
    width: 100%;
    height: 30px;
}

select {
    height: 30px;
    font-family: 'Quicksand', sans-serif;
    margin-right: 1vh;
}

#formButton {
    font-size: large;
    padding: 5px;
}

#myfile::-webkit-file-upload-button {
    padding: 3px;
}

#formButton,
#myfile::-webkit-file-upload-button {
    background-color: $red;
    color: $white;
    font-family: 'Quicksand', sans-serif;
    width: fit-content;
    padding: 0.2em 1em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 2em;
    &:hover {
        background-color: $blue;
    }
}

#myfile {
    width: 70%;
}

#invisibleText {
    display: none;
}

#heart {
    animation-name: example;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    color: $red;
    margin-top: 11vh;
}

@keyframes example {
    0% {
        color: $yellow;
        left: 0px;
        top: 0px;
    }
    25% {
        color: $blue;
        left: 50px;
        top: 0px;
        scale: 1.5
    }
    50% {
        color: $green;
        left: 100px;
        top: 0px;
    }
    75% {
        color: $pink;
        left: 150px;
        top: 0px;
    }
    100% {
        color: $orange;
        left: 200px;
        top: 0px;
    }
}

@media screen and (max-width: 415px) {
    #careerForm {
        width: 90%;
        border-radius: 2%;
    }
    textarea {
        width: 95%;
    }
    #formButton,
    #myfile::-webkit-file-upload-button {
        color: $black;
    }
}