@import "_variables";
#topHeader {
    background-color: $white;
    padding: 1.5vh;
    font-size: 1vw;
    justify-content: space-evenly;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        color: $darkgrey;
    transition: top 0.5s ease-in-out;
    a {
        text-decoration: none;
        color: $darkgrey;
        &:hover {
            color: $blue;
        }
    }
    i {
        margin-right: 5px;
    }
}

#headerSocial {
    margin-right: 5px;
    cursor: pointer;
}

.navbar {
    background-color: $yellow;
    height: 13vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    width: 100%;
    z-index: 1;
    &:hover {
        color: $blue;
    }
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
}

.nav-logo {
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
    flex-grow: 1;
    img {
        height: 12vh;
        width: 12vh;
        transition: transform .2s;
        &:hover {
            transform: scale(1.1);
        }
    }
}

.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
    font-weight: bold;
    &:hover {
        color: $blue;
    }
}

.nav-links {
    color: $black;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
    &:hover {
        color: $blue !important;
        font-weight: bold;
    }
}

.fa-code {
    margin-left: 1rem;
}

.nav-item {
    line-height: 5vh;
    margin-right: 1rem;
    &:hover {
        color: $blue;
    }
}

.nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-icon {
    display: none;
}

.dropdownUl {
    display: none;
    position: absolute;
    background-color: $yellow;
        &:hover {
        color: $blue;
        font-weight: bold;
    }
}

.dropdownUl li {
    float: none;
    display: block;
    cursor: pointer;
    line-height: 3;
    a {
        text-decoration: none;
        color: $black;
        &:hover {
            color: $blue;
            font-weight: bold;
        }
    }
}

.nav-item:hover .dropdownUl {
    display: block;
    float: none;
    padding: 12px 16px;
    text-decoration: none;
    text-align: left;
}

@media screen and (max-width: 798px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1pxsolid #fff;
        position: absolute;
        top: 12vh;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
        font-size: 2.2vw;
    }
    .nav-menu.active {
        background: $yellow;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-item .active {
        border: none;
    }
    .nav-links {
        padding: 0 1.5rem;
        width: 100%;
        display: table;
    }
    .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: $black;
    }
    .nav-item:hover .dropdownUl {
        position: relative;
        text-align: center;
    }
    #topHeader {
        font-size: 1.5vw;
        // grid-template-columns: 1fr 1fr !important;
        div {
            padding-bottom: 2vh;
        }
    }
    .logo a {
        img {
            margin-left: 5vh;
        }
    }
}

@media screen and (max-width: 486px) {
    .nav-menu {
        font-size: 5vw;
    }
    #topHeader {
        font-size: 2vw;}
}