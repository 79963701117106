@import "_variables";
#infantContainer {
    padding-top: 20vh;
    p {
        margin: auto;
        width: 80%;
        line-height: 5vh;
        font-weight: bold;
        padding: 2vh;
        font-size: larger;
        background-color: $white;
    }
    #programsPagePhoto1 {
        margin: 5vh;
        width: 80%;
        border-radius: 0 20% 0 0;
    }
    #programsPagePhoto3 {
        margin: 5vh;
        width: 80%;
        border-radius: 0 20% 0 0;
    }
    #programsPagePhoto2 {
        width: 50%;
        border-radius: 0 20% 0 0;
    }
}

#infantFirstPart {
    background-image: url("../assets/photo-gallery/5.png");
    width: 100%;
    background-repeat: no-repeat;
}

.infantHeaderToddler {
    background-image: linear-gradient(90deg, #ffe75e 25%, #e6c4b3 25%, #e6c4b3 50%, #ffe75e 50%, #ffe75e 75%, #e6c4b3 75%, #e6c4b3 100%);
}

.infantHeaderSchool {
    background-image: linear-gradient(90deg, #ffe75e 25%, #b3c1e6 25%, #b3c1e6 50%, #ffe75e 50%, #ffe75e 75%, #b3c1e6 75%, #b3c1e6 100%);
}

.infantHeader {
    background-image: linear-gradient(90deg, #ffe75e 29.17%, #b3e6cd 29.17%, #b3e6cd 50%, #ffe75e 50%, #ffe75e 79.17%, #b3e6cd 79.17%, #b3e6cd 100%);
}

.infantHeaderSchoolAge {
    background-image: linear-gradient(90deg, #ffe75e 25%, #b3e6b8 25%, #b3e6b8 50%, #ffe75e 50%, #ffe75e 75%, #b3e6b8 75%, #b3e6b8 100%);
}

.infantHeader,
.infantHeaderSchool,
.infantHeaderToddler,
.infantHeaderSchoolAge {
    font-size: 3em !important;
    color: $blue;
    padding: 2vh;
    margin: 2vh;
    border: 1px solid $blue;
    display: inline-block;
}

#infantContainer2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 5vh;
    margin: 10vh 0;
}

#schedule {
    width: 90%;
    text-align: left;
}

#infantContainer2>div:nth-child(3),
#infantContainer2>div:nth-child(6) {
    width: 100%;
}

#headSmall {
    font-size: 4vh;
    color: $red;
}

@media screen and (max-width: 769px) {
    #infantContainer2 {
        grid-template-columns: 1fr;
    }
    p {
        width: auto;
    }
    .infantHeader,
    .infantHeaderSchool,
    .infantHeaderToddler,
    .infantHeaderSchoolAge {
        font-size: 4vh;
    }
    #infantContainer {
        #programsPagePhoto2 {
            width: 50%;
        }
    }
}

@media screen and (max-width: 411px) {
    #infantContainer {
        #infantBaby2,
        #infantBaby3 {
            width: 80%;
        }
    }
    #infantContainer2 span {
        font-size: 4vw;
    }
}