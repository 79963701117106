#galleryTop {
    padding: 10vw;
}



#centerClosureList {
    background-color: $white;
}

@media screen and (max-width:1280px) {
    #galleryTop {
        padding: 14vw;
    }
}

@media screen and (max-width: 768px) {
    #galleryTop {
        padding-top: 25vw;
    }
}

@media screen and (max-width: 411px) {
    #galleryTop {
        padding-top: 32vw;
    }
}